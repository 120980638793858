import { Button, makeStyles, Theme } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import React, { useState } from 'react';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import PrivacyPolicyModal from 'ui/PrivacyPolicyModal';

import { useNavigate } from '../../utilities/navigate';
import { Routes } from '../../infrastructure/routes';

const useStyles = makeStyles<Theme>((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  button: {
    color: theme.palette.common.white,
    width: '100%',
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 185,
    },
  },
  seeBelowButton: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: theme.spacing(-1),
    },
  },
  space: {
    marginLeft: '10px',
  },
}));

type LandingPageButtonsProps = {
  onClickBelow?: () => void;
  onClick: () => void;
  upperButton?: boolean;
};

export const LandingPageButtons = ({ onClickBelow, onClick, upperButton }: LandingPageButtonsProps) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  if (keycloak.authenticated) {
    navigate(Routes.CR_LOGIN);
  }
  const [agreed, setAgreed] = useState<boolean>(() => {
    return localStorage.getItem('privacyPolicyAgreed') === 'true';
  });
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleModal = () => {        
    if (!agreed) {
      setModalOpen(!modalOpen);
    } else {
      keycloak.login();
    }
  };

  const handleAgree = () => {
    setAgreed(true);
    localStorage.setItem('privacyPolicyAgreed', 'true');
    setModalOpen(false);    
    keycloak.login();
    
  };

  return (
    <>
      <PrivacyPolicyModal open={modalOpen} handleClose={handleModal} handleAgree={handleAgree} />

      <div className={clsx(classes.space, classes.seeBelowButton)}>
        {!keycloak.authenticated && (
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.button, classes.seeBelowButton)}
            onClick={handleModal}
            size="large"
          >
            {translate('evaluation')}
          </Button>
        )}
        {!!keycloak.authenticated && upperButton && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => keycloak.logout()}
            size="large"
          >
            {translate('logout')}
          </Button>
        )}
      </div>
    </>
  );
};
