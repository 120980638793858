export const CR_ROUTE = '/company-representative';

export enum CompanyRepresentativeRoutes {
  companySelect = '/company-select',
  assessment = '/self-assessment',
  finished = '/finished',
  payment= '/payment',
  successPayment= '/success-payment',
  invoicesPage= '/invoices',
  result = '/result',
  establishment = '/establishment',
  osh = '/establishment/OSH',

  establishmentDetails = '/details',
  // invoicesPage = "invoicesPage",
}

export const getCompanyRepresentativePath = (route: CompanyRepresentativeRoutes) => `${CR_ROUTE}${route}`;
