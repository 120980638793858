import React from 'react';
import { CircularProgress, Container, makeStyles, Typography } from '@material-ui/core';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { BillsTable } from 'ui/BillsTable';
import { useGetAllBillsAdminQuery } from 'domain/company-representative/payment/api/payments.api';
import { Layout } from 'ui/Layout';

import { AdminLayout } from './ui/AdminLayout';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 0,
    marginTop: theme.spacing(15),
  },

  pageTitle: {
    marginBottom: theme.spacing(1.5),
  },

  createButton: {
    height: '50px',
    width: '200px',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(-7),
    float: 'right',
  },

  header: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(6)}px`,
    },
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(10),
  },

  description: {
    marginBottom: theme.spacing(3),
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
}));

interface BillsInfoType {
  payment_id: string;
  item_name: string;
  billNumber: string;
  due_date: string;
  total_amount: number;
  status: string;
}

export const AdminBills = () => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
//   const [query, setQuery] = useState('');

  const { data: billsInfo } = useGetAllBillsAdminQuery() as {
    data: BillsInfoType[] | undefined;
  };

//   const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
//     const { value } = e.target as HTMLInputElement;
//     setQuery(value);
//   };

  if (!billsInfo) {
    return (
      <Layout maxWidth="lg">
        <Container className={classes.circularProgress}>
          <CircularProgress />
        </Container>
      </Layout>
    );
  }

  return (
    <AdminLayout>
      <Container className={classes.main} maxWidth="lg">
        <div className={classes.header}>
          <Typography variant="h4" className={classes.pageTitle}>
            {translate('invoices')}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.description}>
          {translate('descripeInviocePage')}
        </Typography>
        {/* <Search name={translate('searchBill')} onChange={(e) => handleSearch(e)} /> */}
        <BillsTable billsInfo={billsInfo} />
      </Container>
    </AdminLayout>
  );
};
