import { makeStyles, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Layout } from 'ui/Layout';
import { backButtonBackgroundColor } from 'ui/styles';
import { BillsTable } from 'ui/BillsTable';
import { LoadingWrapper } from 'ui/LoadingWrapper';

import { useGetAllBillEstablishmentQuery } from './api/payments.api';
import { useGetEstablishmentQuery } from '../api/companyRepresentative.api';

const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.lg,
    marginTop: theme.spacing(6),
  },

  menuButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  svgIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    backgroundColor: backButtonBackgroundColor,
  },

  main: {
    padding: 0,
    marginTop: theme.spacing(15),
  },

  pageTitle: {
    marginBottom: theme.spacing(1.5),
  },

  header: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(6)}px`,
    },
  },

  description: {
    marginTop: '12px',
    color: theme.palette.text.secondary,
  },

  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
}));

interface BillsInfoType {
  payment_id: string;
  item_name: string;
  billNumber: string;
  due_date: string;
  total_amount: number;
  status: string;
}
export const InvoicesPage: React.FC = () => {
  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { data: billsInfo } = useGetAllBillEstablishmentQuery({ establishment_id:establishmentId }) as {
    data: BillsInfoType[] | undefined;
  };
  const { data: establishment, isLoading } = useGetEstablishmentQuery(+establishmentId);

  return (
    <Layout maxWidth="lg">
      <Container className={classes.main} maxWidth="lg">
        <div className={classes.header}>
          <Typography variant="h4" className={classes.pageTitle}>
            {translate('invoices')}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.description}>
          {translate('descripeInviocePage')}
        </Typography>
      </Container>
      <LoadingWrapper isLoading={isLoading}>{establishment && <BillsTable billsInfo={billsInfo} />}</LoadingWrapper>
    </Layout>
  );
};

export default InvoicesPage;
