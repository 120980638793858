/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler, Controller, FieldError } from 'react-hook-form';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import {
  emailRegex,
  saudiPhoneNumberRegex,
  phoneNumberRegex,
  taxNumberRegex,
  numberRegex,
  enInputRegex,
  linkedInUrlRegex,
  twitterUrlRegex,
  xUrlRegex,
  facebookUrlRegex,
} from 'infrastructure/constants/regex.constants';
import { useNavigate } from 'utilities/navigate';
import { useCreateTranslate } from 'utilities/translate.hook';
import { EconomicActivity } from 'types/EconomicActivity';
import {
  CompanyRepresentativeRoutes,
  getCompanyRepresentativePath,
} from 'domain/company-representative/CompanyRepresentativeRoutes';
import { useFormStyles } from 'styles/form';
import { Map } from 'ui/Map';
import { MissingInfoAttachments } from 'ui/establishmentAttachments';
import { Hint } from 'ui/Hint';

import { CrFillMissingInfoCity } from './CrFillMissingInfoCity';
import { FormValues, toEstablishmentInfoDto } from '../types/MissingInfo';
import { useAddEstablishmentInfoMutation, useGetCitiesListQuery } from '../api/companyRepresentative.api';
import { getDirection } from '../../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  wrapper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      paddingInline: 120,
      paddingBlock: 40,
    },
  },
  inputField: {
    marginBlock: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginBlock: theme.spacing(2),
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  terms: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'stretch',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      marginInline: -theme.spacing(1),
    },
  },
  button: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      flexDirection: 'row-reverse',
      marginInline: theme.spacing(1),
    },
    borderRadius: 8,
    border: 'solid 1px',
  },
  topic: {
    marginBottom: theme.spacing(1.5),
  },
  topicDescription: {
    color: theme.palette.text.secondary,
  },
  VARSection: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    fontSize: 16,
  },
  headerWrapper: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  sectionHeader: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  language: {
    color: theme.palette.common.white,
  },
  map: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  field: {
    marginTop: theme.spacing(4),
  },
  uploadButton: {
    fontSize: '18px',
    height: 45,
    [theme.breakpoints.up('md')]: {
      width: 150,
    },
    '&.disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[500],
      cursor: 'not-allowed',
      border: '1px solid transparent',
    },
    uploadedContainer: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  uploadButtonSpace: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  uploadButtonDesc: {
    width: '340px',
    marginLeft: theme.spacing(4),
    paddingRight: theme.spacing(12),
    textAlign: 'left',
  },
  uploadedContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
  },
  attachment: {
    flip: false,
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  taxField: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

export interface FillMissingInfoFormProps {
  formValues: Partial<FormValues>;
  economicActivities: EconomicActivity[];
  regionOptions: LocalRegion[];
}

export interface City {
  id: number;
  nameAr: string;
}

export interface LocalRegion {
  id: number;
  nameAr: string;
  cities: City[];
}

export const CrFillMissingInfoForm: React.FC<FillMissingInfoFormProps> = ({
  formValues,
  economicActivities,
  regionOptions,
}) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const navigate = useNavigate();
  const [putEstablishmentInfo] = useAddEstablishmentInfoMutation();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const translateLanding = useCreateTranslate(TranslationNamespace.landingPage);
  const direction = getDirection(i18n.language);
  const fileUploadRef = useRef<{ getAttachments: () => File[] }>(null);
  const [selectedRegionId, setSelectedRegionId] = useState<number | null>(null);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null);
  const { data: cityOptions = [], isFetching: areCitiesLoading } = useGetCitiesListQuery(selectedRegionId ?? 0, {
    skip: !selectedRegionId,
  });
  const [pendingCity, setPendingCity] = useState<string | null>(null);
  const [loadingCities, setLoadingCities] = useState(false);
  const [attachmentsError, setAttachmentsError] = useState(false);

  const companyRepresentativeDetails = [
    {
      name: 'companyRepresentativeName',
      label: 'CRName',
      rules: { required: true },
      disabled: (values: any) => !!values.companyRepresentativeName,
    },
    {
      name: 'email',
      label: 'email',
      rules: { required: true, pattern: { value: emailRegex, message: translateCommon('invalidEmail') } },
      disabled: (values: any) => !!values.email,
    },
    {
      name: 'phoneNumber',
      label: 'phoneNumber',
      rules: {
        required: true,
        pattern: {
          value: saudiPhoneNumberRegex,
          message: translateLanding('invalidPhoneFormat'),
        },
        min: 0,
      },
      type: 'text',
      inputMode: 'numeric',
      InputProps: {
        classes: {
          input: formClasses.numberInput,
        },
      },
    },
    {
      name: 'phoneNumberAdditional',
      label: 'phoneNumberAdditional',
      rules: {
        pattern: {
          value: saudiPhoneNumberRegex,
          message: translateLanding('invalidPhoneFormat'),
        },
      },
      type: 'text',
      inputMode: 'numeric',
      InputProps: {
        classes: {
          input: formClasses.numberInput,
        },
      },
    },
  ];

  const establishmentDetails = [
    {
      name: 'extId',
      label: 'establishmentNumber',
      rules: { required: true },
      disabled: (values: any) => !!values.extId,
    },
    {
      name: 'nameAr',
      label: 'companyNameAr',
      rules: { required: true },
      disabled: (values: any) => !!formValues.nameAr,
    },
    {
      name: 'nameEn',
      label: 'companyNameEn',
      rules: {
        required: true,
        pattern: {
          value: enInputRegex,
          message: translate('enCPInputMassage'),
        },
      },
      disabled: (values: any) => !!formValues.nameEn,
    },
    {
      name: 'mobile',
      label: 'phoneNumber',
      rules: {
        required: true,
        pattern: {
          value: saudiPhoneNumberRegex,
          message: translateLanding('invalidPhoneFormat'),
        },
      },
      type: 'text',
      inputMode: 'numeric',
      dir: direction,
      InputProps: {
        classes: {
          input: formClasses.numberInput,
        },
      },
    },
    {
      name: 'phone',
      label: 'companyPhoneNumber',
      rules: { pattern: phoneNumberRegex },
      disabled: (values: any) => !!formValues.phone,
      type: 'text',
      InputProps: {
        classes: {
          input: formClasses.numberInput,
        },
      },
    },
    {
      name: 'economicActivityNameAr',
      label: 'economicActivity',
      rules: { required: true },
      disabled: (values: any) => !!formValues.economicActivityNameAr,
    },
    {
      name: 'commercialRegistration',
      label: 'commercialRegistration',
      rules: {
        required: true,
        pattern: numberRegex,
      },
      disabled: (values: any) => !!formValues.commercialRegistration,
    },
  ];

  const nationalAddress = [
    { name: 'district', label: 'district' },
    { name: 'street', label: 'street' },
    { name: 'buildingNumber', label: 'buildingNumber', pattern: numberRegex },
    { name: 'postalCode', label: 'postalCode', pattern: numberRegex },
    { name: 'additionalNumber', label: 'additionalNumber', pattern: numberRegex, required: false },
  ];

  const socialMediaLinks = [
    {
      name: 'linkedinUrl',
      label: 'linkedinLink',
      rules: { pattern: { value: linkedInUrlRegex, message: translate('linkedInInputMassage') } },
      disabled: (values: any) => !!formValues.linkedinUrl,
    },
    {
      name: 'twitterUrl',
      label: 'twitterLink',
      rules: { pattern: { value: xUrlRegex || twitterUrlRegex, message: translate('twitterInputMassage') } },
      disabled: (values: any) => !!formValues.twitterUrl,
    },
    {
      name: 'facebookUrl',
      label: 'facebookLink',
      rules: { pattern: { value: facebookUrlRegex, message: translate('facebookInputMassage') } },
      disabled: (values: any) => !!formValues.facebookUrl,
      margin: 'normal',
    },
  ];

  const [hasTaxNumber, setHasTaxNumber] = useState<string>('');

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: formValues,
  });

  const preventDefaultIfEnterClicked = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const preventDefaultIfNotNumberValues = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!numberRegex.test(e.key)) {
      e.preventDefault();
    }
  };
  const onSubmit: SubmitHandler<FormValues> = async (submittedFormValues) => {
    const uploadedFiles = fileUploadRef.current?.getAttachments() || [];

    if (uploadedFiles.length === 0) {
      setAttachmentsError(true);
      return;
    } else {
      setAttachmentsError(false);
    }

    if (!submittedFormValues.id) {
      console.error('Establishment ID is undefined');
      return;
    }

    const formData = new FormData();
    const establishmentInfoDto = toEstablishmentInfoDto(submittedFormValues);

    Object.entries(establishmentInfoDto).forEach(([key, value]) => {
      if (key !== 'upload' && value !== null && value !== undefined) {
        formData.append(key, String(value));
      }
    });

    uploadedFiles.forEach((file) => formData.append('upload', file, file.name));

    try {
      await putEstablishmentInfo({
        establishmentId: submittedFormValues.id,
        establishmentInfo: formData,
      }).unwrap();

      navigate(
        `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/OSH/${submittedFormValues.id}`
      );
    } catch (error) {
      console.error('Submit error:', error);
    }
  };

  const getErrorText = (type: FieldError['type'] | undefined) => {
    switch (type) {
      case 'required':
        return translate('fieldRequired');
      case 'pattern':
        return translate('invalidFormat');
      default:
        return '';
    }
  };
  const goBack: MouseEventHandler = (e) => {
    navigate(getCompanyRepresentativePath(CompanyRepresentativeRoutes.companySelect));
  };

  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateCommon('fieldRequired');
      }

      return error.message;
    }

    return ' ';
  };

  const handleAttachmentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasTaxNumber(event.target.value);
  };

  useEffect(() => {
    if (loadingCities && cityOptions.length > 0) {
      setLoadingCities(false);
    }
  }, [cityOptions, loadingCities]);

  useEffect(() => {
    if (!areCitiesLoading && pendingCity && cityOptions.length > 0) {
      const normalizeText = (text: string) => text.trim().replace(/\s+/g, ' ').toLowerCase();

      const selectedCity = cityOptions.find((c) => normalizeText(c.nameAr) === normalizeText(pendingCity));

      if (selectedCity) {
        setValue('cityId', selectedCity.id);
        setSelectedCityId(selectedCity.id);
      } else {
        console.warn('City not found :', pendingCity);
      }

      setPendingCity(null);
    }
  }, [cityOptions, pendingCity, areCitiesLoading, setValue]);

  const handleRegionChange = (region: LocalRegion | null) => {
    if (!region) {
      setValue('regionId', 0);
      setSelectedRegionId(null);
      setValue('cityId', 0);
      setSelectedCityId(null);
      setPendingCity(null);
    } else {
      setValue('regionId', region.id);
      setSelectedRegionId(region.id);
      setValue('cityId', 0);
      setSelectedCityId(null);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <Typography variant="h5" className={classes.topic}>
          {translate('fillCompanyDetails')}
        </Typography>
        <Typography variant="body2" className={classes.topicDescription}>
          {translate('completeInformation')}
        </Typography>
      </div>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => preventDefaultIfEnterClicked(e)}
      >
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('CRDetails')}</Typography>
          </div>

          {companyRepresentativeDetails.map((field) => (
            <Controller
              key={field.name}
              name={field.name as keyof FormValues}
              control={control}
              rules={field.rules}
              render={({ field: controllerField, fieldState: { invalid, error } }) => (
                <TextField
                  {...controllerField}
                  variant="outlined"
                  className={classes.inputField}
                  error={invalid}
                  helperText={getHelperText(error)}
                  label={translate(field.label)}
                  disabled={field.disabled ? field.disabled(formValues) : false}
                  type={field.type || 'text'}
                  inputMode={
                    field.inputMode as
                      | 'none'
                      | 'text'
                      | 'email'
                      | 'numeric'
                      | 'search'
                      | 'tel'
                      | 'url'
                      | 'decimal'
                      | undefined
                  }
                  InputProps={field.InputProps}
                />
              )}
            />
          ))}
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('companyDetails')}</Typography>
          </div>

          {establishmentDetails.map((field) => (
            <Controller
              key={field.name}
              name={field.name as keyof FormValues}
              control={control}
              rules={field.rules}
              render={({ field: controllerField, fieldState: { invalid, error } }) => (
                <TextField
                  {...controllerField}
                  variant="outlined"
                  className={classes.inputField}
                  error={invalid}
                  helperText={getHelperText(error)}
                  label={translate(field.label)}
                  disabled={field.disabled ? field.disabled(formValues) : false}
                  type={field.type || 'text'}
                  inputMode={
                    field.inputMode as
                      | 'none'
                      | 'text'
                      | 'email'
                      | 'numeric'
                      | 'search'
                      | 'tel'
                      | 'url'
                      | 'decimal'
                      | undefined
                  }
                  dir={field.dir}
                  InputProps={field.InputProps}
                />
              )}
            />
          ))}

          <Typography variant="body2" className={classes.VARSection}>
            {translate('haveAVAT')}
          </Typography>
          <FormControl>
            <RadioGroup value={hasTaxNumber} onChange={handleAttachmentsChange}>
              <FormControlLabel value="yes" control={<Radio required />} label={translateCommon('yes')} />
              <FormControlLabel value="no" control={<Radio required />} label={translateCommon('no')} />
            </RadioGroup>
          </FormControl>
          <Collapse in={hasTaxNumber === 'yes'} className={classes.taxField}>
            <Controller
              name="taxNumber"
              control={control}
              rules={{ required: hasTaxNumber === 'yes', pattern: taxNumberRegex }}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!!formValues.taxNumber}
                  variant="outlined"
                  className={classes.taxField}
                  error={!!errors.taxNumber?.type}
                  helperText={getErrorText(errors.taxNumber?.type)}
                  label={translate('taxNumber')}
                  onKeyPress={preventDefaultIfNotNumberValues}
                />
              )}
            />
          </Collapse>
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('companyAddress')}</Typography>
            <Typography variant="body2" className={classes.topicDescription}>
              {translate('companyAddressDesc')}
            </Typography>
          </div>

          <Controller
            name="googleLocation"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div className={classes.map}>
                {errors.googleLocation?.type && (
                  <FormHelperText className={classes.error}>{getErrorText(errors.googleLocation?.type)}</FormHelperText>
                )}
                <Map
                  setValue={(obj) => {
                    // Get the lat and lng from the map and set it to the form
                    setValue('googleLocation', obj);

                    const normalizeText = (text: string) =>
                      text
                        .trim() // Remove leading/trailing spaces
                        .replace(/\s+/g, ' ') // Replace multiple spaces with one
                        .replace(/^(منطقة|محافظة)\s+/, '') // Remove "منطقة" or "محافظة"
                        .toLowerCase(); // Convert to lowercase

                    const regionName = obj.region ? normalizeText(obj.region) : '';
                    const cityName = obj.city ? normalizeText(obj.city) : null;

                    // Preserve existing API data for address fields
                    const district = formValues.district || obj.district?.trim() || '';
                    const street = formValues.street || obj.street?.trim() || '';
                    const postalCode = formValues.postalCode || (obj.postalCode ? Number(obj.postalCode.trim()) : null);
                    const buildingNumber =
                      formValues.buildingNumber || (obj.buildingNumber ? Number(obj.buildingNumber.trim()) : null);

                    // Ensure a valid region is detected
                    if (!regionName) return;

                    const selectedRegion = regionOptions.find((r) => normalizeText(r.nameAr).includes(regionName));

                    if (selectedRegion) {
                      setValue('regionId', selectedRegion.id);
                      setSelectedRegionId(selectedRegion.id);
                      setPendingCity(cityName);
                    }

                    // Only update address fields if they are empty (preserve API data)
                    if (!formValues.district) setValue('district', district);
                    if (!formValues.street) setValue('street', street);
                    if (!formValues.postalCode && postalCode !== null) setValue('postalCode', postalCode);
                    if (!formValues.buildingNumber && buildingNumber !== null)
                      setValue('buildingNumber', buildingNumber);
                  }}
                />
              </div>
            )}
          />

          <Controller
            name="regionId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                value={regionOptions.find((region) => region.id === selectedRegionId) || null}
                selectOnFocus
                clearOnBlur
                blurOnSelect
                className={classes.inputField}
                onChange={(_, value) => handleRegionChange(value)}
                options={regionOptions}
                getOptionLabel={(region) => region.nameAr || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.regionId?.type}
                    helperText={getErrorText(errors.regionId?.type)}
                    label={translate('region')}
                    variant="outlined"
                    disabled={!!formValues.regionId}
                  />
                )}
              />
            )}
          />

          {!watch('regionId') ? (
            <Controller
              name="cityId"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <CrFillMissingInfoCity
                  regionId={watch('regionId')!}
                  onChange={(e, option) => {
                    if (!option) return;
                    setValue('cityId', option.id);
                    setSelectedCityId(option.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.cityId?.type}
                      helperText={getErrorText(errors.cityId?.type)}
                      label={translate('city')}
                      variant="outlined"
                      disabled={!!formValues.cityId}
                    />
                  )}
                />
              )}
            />
          ) : (
            <Controller
              name="cityId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  options={cityOptions}
                  value={cityOptions.find((c) => c.id === selectedCityId) || null}
                  onChange={(e, option) => {
                    if (!option) return;
                    setValue('cityId', option.id);
                    setSelectedCityId(option.id);
                  }}
                  disabled={!selectedRegionId}
                  getOptionLabel={(city) => city.nameAr || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.cityId?.type}
                      helperText={getErrorText(errors.cityId?.type)}
                      label={translate('city')}
                      variant="outlined"
                      disabled={!selectedRegionId}
                    />
                  )}
                />
              )}
            />
          )}

          {nationalAddress.map((field) => (
            <Controller
              key={field.name}
              name={field.name as keyof FormValues}
              control={control}
              rules={{
                required: field.required !== false,
                ...(field.pattern ? { pattern: field.pattern } : {}),
              }}
              render={({ field: controllerField }) => (
                <TextField
                  {...controllerField}
                  value={watch(field.name as keyof FormValues) || ''}
                  onChange={(e) => setValue(field.name as keyof FormValues, e.target.value)}
                  disabled={!!formValues[field.name as keyof FormValues]}
                  variant="outlined"
                  className={classes.inputField}
                  error={!!errors[field.name as keyof FormValues]?.type}
                  helperText={getErrorText(errors[field.name as keyof FormValues]?.type)}
                  label={translate(field.label)}
                />
              )}
            />
          ))}
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('socialMediaLinks')}</Typography>
          </div>

          {socialMediaLinks.map((field) => (
            <Controller
              key={field.name}
              name={field.name as keyof FormValues}
              control={control}
              rules={field.rules}
              render={({ field: controllerField, fieldState: { invalid, error } }) => (
                <TextField
                  {...controllerField}
                  variant="outlined"
                  className={classes.inputField}
                  label={translate(field.label)}
                  disabled={field.disabled ? field.disabled(formValues) : false}
                  margin={field.margin as 'none' | 'dense' | 'normal' | undefined}
                  error={invalid}
                  helperText={getHelperText(error)}
                />
              )}
            />
          ))}
          {hasTaxNumber && (
            <>
              <MissingInfoAttachments
                ref={fileUploadRef}
                hasTaxNumber={hasTaxNumber}
                direction={direction}
                translate={translate}
                establishment={formValues}
                setAttachmentsError={setAttachmentsError} 
              />
              {attachmentsError && <Hint type="error" text={translateLanding('atLeastOneFile')} />}
            </>
          )}
          <FormControl error={!!errors.areTermsAccepted?.type}>
            <FormControlLabel
              control={
                <Controller
                  name="areTermsAccepted"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Checkbox
                      className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}
                      {...field}
                    />
                  )}
                />
              }
              label={
                <span className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}>
                  <span>{translate('termsAndConditionsPrefix')}</span>
                  <a href="/terms">{translate('termsAndConditions')}</a>
                  <span>{translate('termsAndConditionsPostfix')}</span>
                </span>
              }
              labelPlacement="end"
            />
          </FormControl>
        </div>

        <div className={classes.buttons}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              const field = document.querySelector('.Mui-error');
              field?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
            className={classes.button}
          >
            {translateCommon('save')}
          </Button>
          <Button color="primary" size="large" onClick={goBack} className={classes.button}>
            {translate('goBack')}
          </Button>
        </div>
      </form>
    </div>
  );
};
