import React from 'react';
import i18n from 'i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { Establishment } from 'types/Establishment';
import { Map } from 'ui/Map';
import { PaperCard } from 'ui/PaperCard';
import { DisplayAttachment } from 'ui/establishmentAttachments';
import { notAvailableInfo } from 'utilities/notAvailableInfo';

import { getReverseDirection as getReverseDirectionForAssessmentResultQuestionAttachments } from '../../../../utilities/useLanguage';

const useStyle = makeStyles((theme) => ({
  details: {
    flip: false,
    marginTop: theme.spacing(4),
  },
  establishmentAttachments: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    flip: false,
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
  },
}));

type AdminEstablishmentDetailsProps = {
  establishment: Establishment;
};

export const EstablishmentDetailsBody: React.FC<AdminEstablishmentDetailsProps> = ({ establishment }) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const reverseDirection = getReverseDirectionForAssessmentResultQuestionAttachments(i18n.language);
  const classes = useStyle();

  const geolocation = {
    lat: Number(establishment.latitude),
    lng: Number(establishment.longitude),
  };


  const companyRepresentativeDetails = [
    {
      name: translate('CRName'),
      value: establishment.companyRepresentativeName,
    },
    {
      name: translate('email'),
      value: establishment.email,
    },
    {
      name: translate('phoneNumber'),
      value: establishment.phoneNumber,
    },
    {
      name: translate('phoneNumberAdditional'),
      value: notAvailableInfo(establishment.additionalPhoneNumber),
    },
  ];

  const companyDetails = [
    {
      name: translate('companyNameEn'),
      value: establishment.nameEn,
    },
    {
      name: translate('companyNameAr'),
      value: establishment.nameAr,
    },
    {
      name: translate('taxNumber'),
      value: establishment.taxNumber,
    },
    {
      name: translate('economicActivity'),
      value: establishment.economicActivityNameAr,
    },
    {
      name: translate('phoneNumber'),
      value: establishment.phoneNumber,
    },
    {
      name: translate('totalLaborerCount'),
      value: establishment.totalLaborerCount,
    },
    {
      name: translate('commercialRegistration'),
      value: establishment.commercialRegistration,
    },
  ];

  return (
    <div>
      <PaperCard title={translate('CRDetails')} data={companyRepresentativeDetails} />
      <PaperCard title={translate('companyDetails')} data={companyDetails} />
      <PaperCard title={translate('googleLocation')}>
        <div className={classes.details}>
          <Map markerPosition={geolocation} disabled={true} />
        </div>
      </PaperCard>
      <PaperCard title={translate('uploadAttachments')}>
        <div className={classes.details}>
          {establishment.attachments.length > 0 ? (
            <div dir={reverseDirection} className={classes.establishmentAttachments}>
              {establishment.attachments.map((attachment) => (
                <DisplayAttachment
                  key={attachment.id}
                  attachment={attachment}
                  establishmentId={establishment.id}
                  attachmentId={attachment.id ?? 0}
                  translate={translate}
                />
              ))}
            </div>
          ) : null}
        </div>
      </PaperCard>
    </div>
  );
};
