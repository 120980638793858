import { useParams } from 'react-router-dom';

import { Layout } from 'ui/Layout';
import { LoadingWrapper } from 'ui/LoadingWrapper';
import SadadDetails from 'ui/SadadDetails';

import { useGetPaymentInfoQuery } from './api/payments.api';


interface PaymentInfoType {
  payment_id: number;
  establishment_id: number;
  billNumber: number;
  biller_name: string;
  biller_code: number;
  amount: string;
  tax: string;
  tax_amount: string;
  total_amount: string;
  item_name: string;
}

const PaymentDailog = () => {
  const { establishmentId } = useParams<{ establishmentId: string }>();

  const { data: billInfo } = useGetPaymentInfoQuery({ establishment_id: establishmentId }) as {
    data: PaymentInfoType | undefined;
  };

  return (
    <Layout maxWidth="lg">
      <LoadingWrapper isLoading={!billInfo}>
        {billInfo && establishmentId && <SadadDetails billInfo={billInfo} establishmentId={establishmentId} />}
      </LoadingWrapper>
    </Layout>
  );
};

export default PaymentDailog;
