import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReAuth } from 'infrastructure/persistence/baseQueryWithReAuth';
import { Results } from 'interfaces/Results';

import { tagTypes } from './payment.tags.api';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: baseQueryWithReAuth(),
  tagTypes,
  endpoints: (build) => ({
    getPaymentInfo: build.query<Results<any>, { establishment_id: string }>({
      query: ({ establishment_id }) => ({
        url: `/payment/get_payment_info/?establishment_id=${establishment_id}`,
        method: 'GET',
      }),
    }),
    getPaymentInfoById: build.query<Results<any>, { payment_id: number }>({
      query: ({ payment_id }) => ({
        url: `/payment/get_payment_id/?payment_id=${payment_id}`,
        method: 'GET',
      }),
    }),
    getAllBillEstablishment: build.query<Results<any>, { establishment_id: string }>({
      query: ({ establishment_id }) => ({
        url: `/payment/get_all_bill_establishment/?establishment_id=${establishment_id}`,
        method: 'GET',
      }),
    }),
    getAllBillsAdmin: build.query<Results<any>, void>({
      query: () => ({
        url: '/payment',
        method: 'GET',
      }),
    }),
  }),
});

export const { 
  useGetPaymentInfoQuery, 
  useGetPaymentInfoByIdQuery, 
  useGetAllBillEstablishmentQuery, 
  useGetAllBillsAdminQuery 
} = paymentApi;
