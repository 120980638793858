import { EconomicActivity } from 'types/EconomicActivity';
import { Establishment, EstablishmentDto } from 'types/Establishment';

import { City, Coords, Region } from './Localization';

export type FormValues = Pick<
  Establishment,
  | 'id'
  | 'extId'
  | 'phoneNumber'
  | 'extension'
  | 'mobile'
  | 'email'
  | 'taxNumber'
  | 'economicActivityId'
  | 'district'
  | 'street'
  | 'nameAr'
  | 'nameEn'
  | 'companyRepresentativeName'
  | 'phone'
  | 'linkedinUrl'
  | 'twitterUrl'
  | 'facebookUrl'
  | 'cityId'
  | 'regionId'
  | 'economicActivityNameAr'
  | 'postalCode'
  | 'buildingNumber'
  | 'additionalNumber'
  | 'commercialRegistration'
  | 'upload'
> & {
  phoneNumberAdditional?: string;
  economicActivity: EconomicActivity;
  googleLocation: Coords;
  areTermsAccepted: boolean;
};

export type MissingInfoDto = Omit<
  EstablishmentDto,
  | 'self_assessment_previous_obj_id'
  | 'self_assessment_current_obj_id'
  | 'self_assessment_previous_obj_status'
  | 'self_assessment_current_obj_status'
  | 'self_assessment_previous_obj_score'
  | 'self_assessment_current_obj_score'
  | 'performance_card_request_id'
  | 'self_assessment_attempts'
  | 'self_assessment_attempts_max'
  | 'self_assessment_attempts_left'
  | 'self_assessment_score'
>;

export type MissingInfoEstablishmentDto = Pick<
  EstablishmentDto,
  | 'id'
  | 'ext_id'
  | 'latitude'
  | 'longitude'
  | 'company_representative_name'
  | 'phone'
  | 'phone_number'
  | 'additional_phone_number'
  | 'email'
  | 'city_id'
  | 'region_id'
  | 'street'
  | 'district'
  | 'name_en'
  | 'tax_number'
  | 'extension'
  | 'mobile'
  | 'economic_activity_id'
  | 'linkedin_url'
  | 'twitter_url'
  | 'facebook_url'
  | 'economic_activity_name_ar'
  | 'waselarea'
  | 'waselcity'
  | 'waselstreet'
  | 'waselunitNo'
  | 'waselzipCode'
  | 'commercialRegistration'
  | 'upload'
>;

export type MissingInfo = Pick<
  Establishment,
  | 'additionalPhoneNumber'
  | 'companyRepresentativeName'
  | 'district'
  | 'email'
  | 'extId'
  | 'extension'
  | 'mobile'
  | 'facebookUrl'
  | 'id'
  | 'linkedinUrl'
  | 'nameEn'
  | 'nameAr'
  | 'phone'
  | 'phoneNumber'
  | 'street'
  | 'taxNumber'
  | 'isUpdated'
  | 'twitterUrl'
  | 'cityId'
  | 'regionId'
  | 'economicActivityNameAr'
  | 'postalCode'
  | 'buildingNumber'
  | 'additionalNumber'
  | 'commercialRegistration'
> & {
  region: Region;
  googleLocation: Coords;
  economicActivity: EconomicActivity;
  city: City;
};

export const toMissingInfo = (missingInfoDto: MissingInfoDto): Partial<MissingInfo> => {  
  return {
    email: missingInfoDto.email,
    phoneNumber: missingInfoDto.phone_number,
    taxNumber: missingInfoDto.tax_number,
    extension: missingInfoDto.extension,
    mobile: missingInfoDto.mobile,
    economicActivity: missingInfoDto.economic_activity_id
      ? {
          id: missingInfoDto.economic_activity_id,
          nameAr: missingInfoDto.economic_activity_name_ar,
          nameEn: missingInfoDto.economic_activity_name_en,
        }
      : undefined,
    phone: missingInfoDto.phone,
    region: missingInfoDto.region_id
      ? {
          id: missingInfoDto.region_id,
          nameAr: missingInfoDto.region_name_ar,
          nameEn: missingInfoDto.region_name_en,
        }
      : undefined,
    city: missingInfoDto.city_id
      ? {
          id: missingInfoDto.city_id,
          nameAr: missingInfoDto.city_name_ar,
          nameEn: missingInfoDto.city_name_en,
        }
      : undefined,
    district: missingInfoDto.waselarea,
    street: missingInfoDto.waselstreet,
    linkedinUrl: missingInfoDto.linkedin_url,
    twitterUrl: missingInfoDto.twitter_url,
    facebookUrl: missingInfoDto.facebook_url,
    extId: missingInfoDto.ext_id,
    economicActivityNameAr: missingInfoDto.economic_activity_name_ar,
    cityId: missingInfoDto.city_id,
    additionalPhoneNumber: missingInfoDto.additional_phone_number,
    companyRepresentativeName: missingInfoDto.company_representative_name,
    nameAr: missingInfoDto.name_ar,
    nameEn: missingInfoDto.name_en,
    googleLocation:
      missingInfoDto.latitude && missingInfoDto.longitude
        ? {
            lat: +missingInfoDto.latitude,
            lng: +missingInfoDto.longitude,
          }
        : undefined,
    id: missingInfoDto.id,
    regionId: missingInfoDto.region_id,
    isUpdated: missingInfoDto.is_updated,
    buildingNumber: missingInfoDto.waselunitNo,
    postalCode: missingInfoDto.waselzipCode,
    commercialRegistration: missingInfoDto.commercialRegistration,

  };
};

export const toFormValues = (missingInfo: Partial<MissingInfo>) => {  
  const formValues: Partial<FormValues> = {
    id: missingInfo.id,
    extId: missingInfo.extId,
    companyRepresentativeName: missingInfo.companyRepresentativeName,
    email: missingInfo.email,
    phoneNumber: missingInfo.phoneNumber,
    phoneNumberAdditional: missingInfo.additionalPhoneNumber,
    nameAr: missingInfo.nameAr,
    nameEn: missingInfo.nameEn,
    taxNumber: missingInfo.taxNumber,
    extension: missingInfo.extension,
    mobile: missingInfo.mobile,
    economicActivityId: missingInfo.economicActivity?.id,
    phone: missingInfo.phone,
    regionId: missingInfo.region?.id,
    cityId: missingInfo.city?.id,
    district: missingInfo.district,
    street: missingInfo.street,
    linkedinUrl: missingInfo.linkedinUrl,
    twitterUrl: missingInfo.twitterUrl,
    facebookUrl: missingInfo.facebookUrl,
    googleLocation: missingInfo.googleLocation,
    areTermsAccepted: false,
    economicActivityNameAr: missingInfo.economicActivityNameAr,
    postalCode: missingInfo.postalCode,
    buildingNumber: missingInfo.buildingNumber,
    additionalNumber: missingInfo.additionalNumber,
    commercialRegistration: missingInfo.commercialRegistration,
  };
  const formValuesEntries = Object.entries(formValues).map(([key, value]) =>
    value === null || value === undefined ? [key, ''] : [key, value]
  );
  const formValuesNotNulls = Object.fromEntries(formValuesEntries);

  return formValuesNotNulls as Partial<FormValues>;
};

export const toEstablishmentInfoDto = (formValues: FormValues): MissingInfoEstablishmentDto => ({
  id: formValues.id,
  ext_id: formValues.extId,
  latitude: String(formValues.googleLocation.lat),
  longitude: String(formValues.googleLocation.lng),
  company_representative_name: formValues.companyRepresentativeName,
  phone: formValues.phone,
  phone_number: formValues.phoneNumber,
  additional_phone_number: formValues.phoneNumberAdditional || '',
  email: formValues.email,
  city_id: formValues.cityId,
  region_id: formValues.regionId,
  street: formValues.street,
  district: formValues.district,
  name_en: formValues.nameEn,
  tax_number: formValues.taxNumber,
  extension: formValues.extension,
  mobile: formValues.mobile,
  economic_activity_id: formValues.economicActivityId,
  linkedin_url: formValues.linkedinUrl,
  twitter_url: formValues.twitterUrl,
  facebook_url: formValues.facebookUrl,
  economic_activity_name_ar: formValues.economicActivityNameAr,
  waselarea: formValues.district,
  waselcity: '',
  waselstreet: formValues.street,
  waselunitNo: formValues.buildingNumber,
  waselzipCode: formValues.postalCode,
  commercialRegistration: formValues.commercialRegistration,
  upload: formValues.upload,
});
