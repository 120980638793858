import { TranslateFn } from 'utilities/translate.hook';
import { SelfAssessmentPassingStatus } from '../../../types/Assessment';

export const getAttemptsMessageFn =
  (translate: TranslateFn) =>
  (passingStatus: SelfAssessmentPassingStatus | null, attemptsNumber: number = 10) => {
    switch (passingStatus) {
      case SelfAssessmentPassingStatus.BELOW_PASSING_SCORE:
        if (attemptsNumber > 1) {
          return translate('attemptsFailInfo', {
            attemptsInfo: `${attemptsNumber}`,
          });
        }
        if (attemptsNumber === 1) {
          return translate('attemptsFailInfoSingular');
        }
        return translate('retryBlocked', {
          attemptsInfo: `${attemptsNumber}`,
        });

      case SelfAssessmentPassingStatus.ABOVE_HIGH_SCORE_VALUE:
      case SelfAssessmentPassingStatus.BELOW_HIGH_SCORE_VALUE:
        return (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>  
              {translate('youHaveSuccessfully')}
            </li>
          </ul>
        );

      default:
        throw new Error('Unsupported passing SA status');
    }
  };
