
import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import i18n from 'i18next';

import { Button } from 'ui/Button';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import OshStripes from 'images/osh-stripes.svg';
import {
  isAdmin as isAdminCheck,
  isInspector as isInspectorCheck,
  isAuditor as isAuditorCheck,
} from 'infrastructure/services/auth.utils';
import { useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { selectRole } from 'infrastructure/services/authSlice.selector';
import { useGetMeQuery } from 'infrastructure/services/user.api';
import LogoColorEng from 'ui/icons/LogoColorEng.svg';
import LogoColor from 'images/logo-color.svg';

import { getAdminPath } from '../../admin/AdminRoutes';
import { getInspectorPath } from '../../inspector/InspectorRoutes';
import { getAuditorPath } from '../../auditor/AuditorRoutes';
import { AdminHeaderMenu } from '../../admin/ui/AdminHeaderMenu';
import { InspectorHeaderMenu } from '../../inspector/ui/InspectorHeaderMenu';
import { Routes } from '../Routes';
import { AuditorHeaderMenu } from '../../auditor/ui/AuditorHeaderMenu';
import { ProfileMenu } from './ProfileMenu';
import { getReverseDirection as getReverseDirectionForHeader } from '../../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  header: {
    flip: false,
    backgroundColor: '#FFFF',
    height: theme.spacing(10),
    padding: `0 ${theme.spacing(2)}px`,
  },

  main: {
    flip: false,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },

  logo: {
    height: 40,
    marginRight: '65px',
    marginLeft: '45px',
    [theme.breakpoints.up('md')]: {
      height: 48,
    },
  },

  imageWrapper: {
    justifySelf: 'end',
    display: 'flex',
    alignItems: 'center',
  },
  language: {
    color: theme.palette.primary.main,
  },
}));

export const Header = () => {
  const classes = useStyles();
  const isAdminLogged = isAdminCheck(useAppSelector(selectRole));
  const isInspectorLogged = isInspectorCheck(useAppSelector(selectRole));
  const isAuditorLogged = isAuditorCheck(useAppSelector(selectRole));
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const { data: currentUser, refetch } = useGetMeQuery();

  const handleOnclick = () => {
    const newLang = i18n.language === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(newLang);
  };
  useEffect(() => {
    if (currentUser?.lang !== i18n.language) {
      refetch();
    }
    const currentLang = currentUser?.lang;
    i18n.changeLanguage(currentLang);
  }, [currentUser, refetch]);
  const reverseDirection = getReverseDirectionForHeader(i18n.language);

  return (
    <header className={classes.header} dir={reverseDirection}>
      <Container maxWidth="lg" className={classes.main}>
        {isAdminLogged && (
          <ProfileMenu getPath={getAdminPath} withText={true} redirectPath={getAdminPath(Routes.login)} />
        )}
        {isInspectorLogged && (
          <ProfileMenu getPath={getInspectorPath} withText={true} redirectPath={getInspectorPath(Routes.login)} />
        )}
        {isAuditorLogged && (
          <ProfileMenu getPath={getAuditorPath} withText={true} redirectPath={getAuditorPath(Routes.login)} />
        )}

        {isAdminLogged && <AdminHeaderMenu />}
        {isInspectorLogged && <InspectorHeaderMenu />}
        {isAuditorLogged && <AuditorHeaderMenu />}

        {!isAdminLogged && !isAuditorLogged && !isInspectorLogged && (
          <Button className={classes.language} onClick={handleOnclick}>
            {i18n.language === 'ar' ? translate('english') : translate('arabic')}
          </Button>
        )}

        <div className={classes.imageWrapper}>
          <Hidden smDown>
            <img alt="osh" src={OshStripes} />
          </Hidden>
          {i18n.language === 'ar' ? (
          <img src={LogoColor} alt="Logo-color" className={classes.logo} />
           ) : (
             <img src={LogoColorEng} alt="Logo-color" className={classes.logo} />
           )}
        </div>
      </Container>
    </header>
  );
};
