import { makeStyles, Typography, Link } from '@material-ui/core';
import clsx from 'clsx';

import { Establishment } from 'types/Establishment';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { useNavigate } from 'utilities/navigate';

import { getCompanyRepresentativePath, CompanyRepresentativeRoutes } from './CompanyRepresentativeRoutes';

const useStyles = makeStyles((theme) => ({
  active: {
    opacity: '1 !important',
  },
  navBar: {
    display: '-webkit-box',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  navElement: {
    flip: false,
    color: theme.palette.primary.main,
    paddingRight: '80px',
    opacity: '0.6',
  },
}));

type CompanyRepresentativeCompanyHeaderProps = {
  establishment: Establishment;
};
export const CompanyRepresentativeHeader: React.FC<CompanyRepresentativeCompanyHeaderProps> = ({ establishment }) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const navigate = useNavigate();

  const routesData = [
    {
      label: translate('invoicesTitle'),
      key: `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.invoicesPage)}/${establishment.id}`, 
      handler: () =>
        navigate(
          `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.invoicesPage)}/${establishment.id}`
        ),
      route: CompanyRepresentativeRoutes.invoicesPage, 
    },
    {
      label: translate('establishmentDetailsTitle'),
      key: `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/${establishment.id}/details`,
      handler: () =>
        navigate(
          `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/${establishment.id}/details`
        ),
      route: CompanyRepresentativeRoutes.establishment,
    },

    // {
    //   label: translate('ChampionsAward'),
    //   key: `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/CA/${establishment.id}`,
    //   handler: () =>
    //     navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/CA/${establishment.id}`),
    //   route: CompanyRepresentativeRoutes.establishment,
    // },
    {
      label: translate('selfAssessment'),
      key: `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/OSH/${establishment.id}`,
      handler: () =>
        navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/OSH/${establishment.id}`),
      route: CompanyRepresentativeRoutes.establishment,
    },
  ];


  return (
    <>
      <div className={classes.navBar}>
        {routesData.map(({ label, handler, route, key }) => (
          <Typography key={label} variant="body1">
            <Link
              className={clsx(classes.navElement, key === window.location.pathname && classes.active)}
              underline="none"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handler();
              }}
            >
              {label}
            </Link>
          </Typography>
        ))}
      </div>
    </>
  );
};
