import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { AdminRoute, RedirectToAllowedRoute } from 'ui/PrivateRoute';
import { Role } from 'infrastructure/services/auth.type';
import { SAResultContainer } from 'ui/SAResultContainer';

import { AdminDashboard } from './admin-dashboard/AdminDashboard';
import { SetPassword } from '../common/set-password/SetPassword';
import { AdminLogin } from './admin-login/AdminLogin';
import { AdminAuthenticate } from './admin-authenticate/AdminAuthenticate';
import { AdminQuestionnaire } from './admin-questionnaire/AdminQuestionnaire';
import { AdminEstablishments } from './admin-establishments/AdminEstablishments';
import { AdminEstablishmentDetails } from './admin-establishments/admin-establishments-details/AdminEstablishmentDetails';
import { PCRequestList } from './admin-pcrequests/PCRequestList';
import { PCRequestDetails } from './admin-pcrequests/admin-pcrequests-details/PCRequestDetails';
import { PCRequestAssessment } from './admin-pcrequest-assessment/PCRequestAssessment';
import { PCRequestAssessmentFinished } from '../common/pcrequest-assessment/PCRequestAssessmentFinished';
import { UsersList } from './admin-users/UsersList';
import { AdminAssessmentCompare } from './admin-assessment-compare/AdminAssessmentCompare';
import { REACT_APP_GOOGLE_RECAPCHA_KEY } from '../../infrastructure/core/variables';
import { Routes } from '../common/Routes';
import { getAdminPath } from './AdminRoutes';
//import { SelectPlatformButtons } from '../common/select-platform/SelectPlatformButtons';
//import { CAPcReviewAnswer } from '../common/pcrequest-assessment/CAPcReviewAnswer';
import { TypeOfPlatform } from '../../infrastructure/services/auth.type';
import { AdminBills } from './AdminBills';

export const AdminRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <AdminRoute exact path={path}>
        <Redirect to={`${path}${Routes.dashboard}`} />;
      </AdminRoute>
      <AdminRoute path={`${path}${Routes.dashboard}`}>
        <AdminDashboard />
      </AdminRoute>

      {/* <AdminRoute exact path={path}>
        <Redirect to={`${path}${Routes.selectPlatform}`} />;
      </AdminRoute>
      <AdminRoute path={`${path}${Routes.selectPlatform}`}>
        <SelectPlatformButtons getPath={getAdminPath} />
      </AdminRoute> */}

      <RedirectToAllowedRoute
        role={Role.Admin}
        path={`${path}${Routes.login}`}
        redirectPath={`${path}${Routes.authenticate}`}
      >
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_GOOGLE_RECAPCHA_KEY || ''}>
          <AdminLogin />
        </GoogleReCaptchaProvider>
      </RedirectToAllowedRoute>

      <RedirectToAllowedRoute
        role={Role.Admin}
        path={`${path}${Routes.authenticate}`}
        redirectPath={`${path}${Routes.dashboard}`}
      >
        <AdminAuthenticate />
      </RedirectToAllowedRoute>

      <AdminRoute path={`${path}${Routes.questionnaire}/:id`}>
        <AdminQuestionnaire />
      </AdminRoute>
      <AdminRoute path={`${path}${Routes.questionnaire}`}>
        <AdminQuestionnaire />
      </AdminRoute>
      <AdminRoute exact path={`${path}${Routes.establishments}`}>
        <AdminEstablishments />
      </AdminRoute>
      <AdminRoute exact path={`${path}${Routes.establishment}/:id`}>
        <AdminEstablishmentDetails />
      </AdminRoute>
      <AdminRoute path={`${path}${Routes.establishment}/:establishmentId${Routes.saResults}/:id${Routes.answers}`}>
        <SAResultContainer />
      </AdminRoute>

      <AdminRoute exact path={`${path}${Routes.pcRequests}`}>
        <PCRequestList />
      </AdminRoute>
      <AdminRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId`}>
        <PCRequestDetails />
      </AdminRoute>

      <AdminRoute exact path={`${path}${Routes.users}`}>
        <UsersList />
      </AdminRoute>

      <AdminRoute exact path={`${path}${Routes.bills}`}>
        <AdminBills />
      </AdminRoute>

      <AdminRoute exact path={`${path}${Routes.setPassword}`}>
        <SetPassword />
      </AdminRoute>

      {/* <AdminRoute
        exact
        path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}/:assessmentId${Routes.reviewAnswer}`}
      >
        <CAPcReviewAnswer getPath={getAdminPath } platform={TypeOfPlatform.CA} />
      </AdminRoute> */}

      <AdminRoute
        exact
        path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}/:assessmentId${Routes.finished}`}
      >
        <PCRequestAssessmentFinished getPath={getAdminPath} platform={TypeOfPlatform.OSH}/>
      </AdminRoute>

      <AdminRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}`}>
        <PCRequestAssessment />
      </AdminRoute>

      <AdminRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.compare}`}>
        <AdminAssessmentCompare />
      </AdminRoute>
    </Switch>
  );
};