import { useEffect, useState } from 'react';
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';

import { Layout } from 'ui/Layout';
import { SuccessMarker } from 'ui/SuccessMarker';
import { useNavigate } from 'utilities/navigate';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import {
  CompanyRepresentativeRoutes,
  getCompanyRepresentativePath,
} from 'domain/company-representative/CompanyRepresentativeRoutes';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  paperText: {
    color: 'grey',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  button: {
    width: '276px',
    height: '50px',
    marginRight: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
}));

type SuccessPaymentProps = {
  assessmentId?: number;
  paymentInfo?: any;
};

const SuccessPayment: React.FC<SuccessPaymentProps> = ({ paymentInfo }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { establishmentId } = useParams<{ establishmentId: string }>();

  useEffect(() => {
    // get the assessment id from local storage
    const storedId = localStorage.getItem('assessmentId');
    if (storedId) {
      setAssessmentId(parseInt(storedId, 10));
    }
  }, []);

  const [assessmentId, setAssessmentId] = useState<number | null>(null);
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const billNumber = queryParams.get('billNumber');

  return (
    <Layout maxWidth="lg">
      <Paper className={classes.paper}>
        <SuccessMarker wrapperHeight={100} />
        <div className={classes.paperText}>
          <Typography>{translate('successMassegePayment', { billNumber: `${billNumber}` })}</Typography>
          <Typography>{translate('usingSadad')}</Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={() => {
              navigate(
                `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${assessmentId}${
                  CompanyRepresentativeRoutes.result
                }`
              );
            }}
          >
            {translate('viewAnswers')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              navigate(
                `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.invoicesPage)}/${establishmentId}/
                }`
              );
            }}
          >
            {translate('viewInvoices')}
          </Button>
        </div>
      </Paper>
    </Layout>
  );
};

export default SuccessPayment;
