import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { CompanyRepresentativeRoute } from 'ui/PrivateRoute';
import { SAResultContainer } from 'ui/SAResultContainer';

import { CompanyRepresentativeEstablishmentSelection } from './company-representative-login/CompanyRepresentativeEstablishmentSelection';
import { CompanyRepresentativeSA } from './company-representative-sa/CompanyRepresentativeSA';
import { CompanyRepresentativeRoutes } from './CompanyRepresentativeRoutes';
import { CompanyRepresentativeSAFinished } from './company-representative-sa/CompanyRepresentativeSAFinished';
import { CREstablishmentDetails } from './company-representative-establishment/CREstablishmentDetails';
import { CompanyRepresentative } from './CompanyRepresentativeCompany';
import { TypeOfPlatform } from '../../infrastructure/services/auth.type';
import PaymentDailog from './payment/PaymentDailog';
import SuccessPayment from './payment/SuccessPayment';
import InvoicesPage from './payment/InvoicesPage';

export const CompanyRepresentativeRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <CompanyRepresentativeRoute
        path={`${path}${CompanyRepresentativeRoutes.assessment}/:id${CompanyRepresentativeRoutes.result}`}
      >
        <SAResultContainer />
      </CompanyRepresentativeRoute>

      <CompanyRepresentativeRoute
        path={`${path}${CompanyRepresentativeRoutes.assessment}/:id${CompanyRepresentativeRoutes.finished}`}
      >
        <CompanyRepresentativeSAFinished />
      </CompanyRepresentativeRoute>

      <CompanyRepresentativeRoute
        path={`${path}${CompanyRepresentativeRoutes.assessment}/:establishmentId${CompanyRepresentativeRoutes.payment}`}
      >
        <PaymentDailog />
      </CompanyRepresentativeRoute>


      <CompanyRepresentativeRoute
        path={`${path}${CompanyRepresentativeRoutes.assessment}/:establishmentId${CompanyRepresentativeRoutes.successPayment}`}
      >
        <SuccessPayment />
      </CompanyRepresentativeRoute>

      <CompanyRepresentativeRoute
        path={`${path}${CompanyRepresentativeRoutes.invoicesPage}/:establishmentId`}
      >
        <InvoicesPage />
      </CompanyRepresentativeRoute>

      <CompanyRepresentativeRoute path={`${path}${CompanyRepresentativeRoutes.assessment}/:id`}>
        <CompanyRepresentativeSA />
      </CompanyRepresentativeRoute>

      <CompanyRepresentativeRoute
        path={`${path}${CompanyRepresentativeRoutes.establishment}/:establishmentId${CompanyRepresentativeRoutes.establishmentDetails}`}
      >
        <CREstablishmentDetails />
      </CompanyRepresentativeRoute>

      <CompanyRepresentativeRoute
        exact
        path={`${path}${CompanyRepresentativeRoutes.establishment}/OSH/:establishmentId`}
      >
        <CompanyRepresentative platform={TypeOfPlatform.OSH} />
      </CompanyRepresentativeRoute>

      {/* <CompanyRepresentativeRoute
        exact
        path={`${path}${CompanyRepresentativeRoutes.establishment}/CA/:establishmentId`}
      >
        <CompanyRepresentative platform={TypeOfPlatform.CA} />
      </CompanyRepresentativeRoute> */}
      <CompanyRepresentativeRoute path={`${path}${CompanyRepresentativeRoutes.companySelect}`}>
        <CompanyRepresentativeEstablishmentSelection />
      </CompanyRepresentativeRoute>
    </Switch>
  );
};
