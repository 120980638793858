import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, IconButton, Paper, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

import { useGetEstablishmentAttachmentsQuery } from 'domain/admin/api/admin.api';

import { CheckGreyIcon, CrossCloseGreyIcon, DownloadIcon } from './icons/icons';
import { Hint } from './Hint';

const Input = styled('input')({
  display: 'none',
});

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(4),
  },
  uploadButton: {
    fontSize: '18px',
    height: 45,
    [theme.breakpoints.up('md')]: {
      width: 150,
    },
    '&.disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[500],
      cursor: 'not-allowed',
      border: '1px solid transparent',
    },
  },
  uploadButtonSpace: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  uploadedContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
  },
  attachment: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  establishmentAttachments: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
  },
  establishmentText: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '400px',
    alignItems: 'center',
  },
  establishmentName: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

interface MissingInfoAttachmentsProps {
  hasTaxNumber: string;
  direction: string;
  translate: (key: string) => string;
  establishment: any;
  setAttachmentsError: React.Dispatch<React.SetStateAction<boolean>>; 
}

export const MissingInfoAttachments = forwardRef(
  ({ hasTaxNumber, direction, translate, setAttachmentsError }: MissingInfoAttachmentsProps, ref) => {
    const [attachments, setAttachments] = useState<File[]>([]);
    const [maxAttachmentsSelected, setMaxAttachmentsSelected] = useState(false);
    const classes = useStyles();

    const handleFileChange = ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      if (files && files.length) {
        const newFiles = Array.from(files);
        
        if (newFiles.length > 5 || attachments.length + newFiles.length > 5) {
          setMaxAttachmentsSelected(true);
          return;
        }
    
        setAttachments((existing) => [...existing, ...newFiles]);
        setMaxAttachmentsSelected(false);
    
        setAttachmentsError(false);
      }
    };

    const removeAttachment = (id: number) => {
      const updatedAttachments = attachments.filter((_, i) => i !== id);
      setAttachments(updatedAttachments);
      if (updatedAttachments.length < 5) {
        setMaxAttachmentsSelected(false);
      }
    };

    useImperativeHandle(ref, () => ({
      getAttachments: () => attachments,
    }));

    return (
      <div>
        <Typography variant="h6" className={classes.field}>
          {translate('uploadDocuments')}
        </Typography>
        <Typography variant="body2" className={classes.field}>
          {hasTaxNumber === 'yes' ? translate('uploadTaxCertificate') : translate('uploadNonTaxCertificate')}
        </Typography>
        <div dir={direction} className={classes.uploadButtonSpace}>
          <label>
            <Input
              type="file"
              onChange={handleFileChange}
              accept=".png,.jpg,.jpeg,.pdf,.heic,.hevc,.heif"
              multiple
              disabled={attachments.length >= 5}
            />
            <Button
              size="large"
              variant="outlined"
              component="span"
              color="secondary"
              className={`${classes.uploadButton} ${attachments.length >= 5 ? 'disabled' : ''}`}
            >
              {translate('upload')}
            </Button>
          </label>
        </div>
        <div dir={direction} className={classes.uploadedContainer}>
          {attachments.map((file, i) => (
            <Button
              key={i}
              variant="outlined"
              size="medium"
              color="secondary"
              className={classes.attachment}
              startIcon={<CheckGreyIcon fill="#148287" />}
              endIcon={<CrossCloseGreyIcon fill="#9E9E9E" onClick={() => removeAttachment(i)} />}
            >
              {file.name}
            </Button>
          ))}
        </div>
        {maxAttachmentsSelected ? <Hint type="error" text={translate('maxAttachmentsSelected')} /> : null}
      </div>
    );
  }
);

interface DisplayAttachmentProps {
  attachment: any;
  establishmentId: number;
  attachmentId: number;
  translate: (key: string) => string;
}
 
export const DisplayAttachment: React.FC<DisplayAttachmentProps> = ({
  attachment,
  attachmentId,
  translate,
}) => {
  const [fetchData, setFetchData] = useState(false);
  const { data } = useGetEstablishmentAttachmentsQuery(
    { documentId: attachmentId },
    { skip: !fetchData }
  );
  const classes = useStyles();

  const handleDownloadClick = () => {
    setFetchData(true);
  };

  if (data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachment.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    setFetchData(false);
  }

  return (
    <Paper
      className={classes.establishmentAttachments}
      key={`result-answer-attachment-${attachment.id}`}
      variant="outlined"
    >
      <div className={classes.establishmentText}>
        <Typography noWrap className={classes.establishmentName} variant="body2">
          {attachment.name}
        </Typography>
        <IconButton color="secondary" onClick={handleDownloadClick}>
          <SvgIcon component={DownloadIcon} />
        </IconButton>
      </div>
    </Paper>
  );
};
