import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Chip, ListItemText, Menu, SvgIcon } from '@material-ui/core';

import InvoicePopup from 'domain/company-representative/payment/InvoicePopup';
import { InvoicesTableHeader } from 'domain/company-representative/payment/InvoicesTableHeader';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'domain/common/utils/importLib';

import { CircleButton } from './buttons/CircleButton';
import { DownloadIcon, ActionIcon, RenewInvoiceIcon, ViewDetailsIcon } from './icons/icons';
import { backButtonBackgroundColor } from './styles';
import { CustomMenuItem } from './CustomMenuItem';
const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.lg,
    marginTop: theme.spacing(6),
  },
  actionCell: {
    padding: '6px',
  },
  menuButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  list: {
    marginRight: 6,
  },
  renewIcom: {
    marginRight: 6,
    height: 22,
    width: 22,
  },
  svgIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    backgroundColor: backButtonBackgroundColor,
  },
  transactionId: { width: '160px', textAlign: 'center' },
  serviceType: { width: '120px', textAlign: 'center' },
  invoiceNumber: { width: '120px', textAlign: 'center' },
  expiredDate: { width: '120px', textAlign: 'center' },
  invoiceAmount: { width: '150px', textAlign: 'center' },
  requestsNumber: { width: '100px', textAlign: 'center' },
  status: { width: '120px', textAlign: 'center' },
  actions: { width: '90px', textAlign: 'center' },
}));

type BillsInfoType = {
  payment_id: string;
  item_name: string;
  billNumber: string;
  due_date: string;
  total_amount: number;
  status: string;
  tax?: string;
  tax_amount?: number;
  biller_code?: number;
};

type BillsApiResponse = {
  count: number;
  next: null | string;
  previous: null | string;
  results: BillsInfoType[];
};

type BillsTableProps = {
  billsInfo?: BillsInfoType[] | BillsApiResponse;
};

export const BillsTable: React.FC<BillsTableProps> = ({ billsInfo = [] }) => {
  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBill, setSelectedBill] = useState<BillsInfoType | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date
      .toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .replace(',', '');
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, bill: BillsInfoType) => {
    setAnchorEl(event.currentTarget);
    setSelectedBill(bill);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewPaymentDetails = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const getStatusTranslation = (status: string) => {
    switch (status) {
      case 'paid':
        return (
          <Chip
            label={translate('paid')}
            style={{ backgroundColor: '#e8f5e9', color: '#3B873E', width: '100%', padding: '5px' }}
          />
        );
      case 'Expired':
        return (
          <Chip
            label={translate('expired')}
            style={{ backgroundColor: '#eaeaea', color: '#696969', width: '100%', padding: '5px' }}
          />
        );
      case 'Created':
        return (
          <Chip
            label={translate('waitingForPayment')}
            style={{ backgroundColor: '#fcedc1', color: '#856620', width: '100%', padding: '5px' }}
          />
        );
      default:
        return status;
    }
  };

  const normalizedBillsInfo: BillsInfoType[] = Array.isArray(billsInfo)
    ? billsInfo
    : (billsInfo as BillsApiResponse)?.results || [];

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table size="medium">
          <InvoicesTableHeader />
          <TableBody>
            {normalizedBillsInfo.length > 0 &&
              normalizedBillsInfo.map((bill: BillsInfoType) => (
                <TableRow key={bill.payment_id}>
                  <TableCell className={classes.transactionId}>{bill.payment_id}</TableCell>
                  <TableCell className={classes.serviceType}>{bill.item_name}</TableCell>
                  <TableCell className={classes.invoiceNumber}>{bill.billNumber}</TableCell>
                  <TableCell className={classes.invoiceNumber}>{formatDate(bill.due_date)}</TableCell>
                  <TableCell className={classes.invoiceAmount}>{bill.total_amount}</TableCell>
                  <TableCell className={classes.requestsNumber}>{getStatusTranslation(bill.status)}</TableCell>
                  <TableCell className={classes.actions}>
                    <div className={classes.menuButtonWrapper}>
                      <CircleButton
                        size={20}
                        icon={ActionIcon}
                        className={classes.svgIcon}
                        viewBoxWidth={22}
                        viewBoxHeight={16}
                        onClick={(event) => handleMenuClick(event, bill)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {selectedBill?.status === 'Created' && (
          <CustomMenuItem onClick={handleViewPaymentDetails}>
            <SvgIcon className={classes.list} component={ViewDetailsIcon} />
            <ListItemText className={classes.list}>{translate('sadadInfo')}</ListItemText>
          </CustomMenuItem>
        )}
        {selectedBill?.status === 'paid' && (
          <>
            <CustomMenuItem onClick={handleViewPaymentDetails}>
              <SvgIcon className={classes.list} component={ViewDetailsIcon} />
              <ListItemText className={classes.list}>{translate('sadadInfo')}</ListItemText>
            </CustomMenuItem>
            <CustomMenuItem onClick={handleViewPaymentDetails}>
              <SvgIcon className={classes.list} component={DownloadIcon} />
              <ListItemText className={classes.list}>{translate('downloadInvoice')}</ListItemText>
            </CustomMenuItem>
          </>
        )}
        {selectedBill?.status === 'Expired' && (
          <CustomMenuItem onClick={handleViewPaymentDetails}>
            <SvgIcon className={classes.renewIcom} component={RenewInvoiceIcon} />
            <ListItemText className={classes.list}>{translate('reNewInvoice')}</ListItemText>
          </CustomMenuItem>
        )}
      </Menu>
      {selectedBill && (
        <InvoicePopup
          open={openDialog}
          onClose={handleDialogClose}
          billInfo={selectedBill}
          statusProp={selectedBill.status}
        />
      )}
    </>
  );
};
