import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

const tableCellStyle = (width: number) => ({
  minWidth: `${width}px`,
  maxWidth: `${width}px`,
});

const useStyles = makeStyles((theme) => ({
  actions: {
    ...tableCellStyle(98),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  status: {
    ...tableCellStyle(250),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  serviceType: {
    ...tableCellStyle(120),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  expiredDate: {
    ...tableCellStyle(120),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  invoiceNumber: {
    ...tableCellStyle(120),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  invoiceAmount: {
    ...tableCellStyle(150),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  requestsNumber: {
    ...tableCellStyle(100),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  transactionId: {
    ...tableCellStyle(160),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1), 
  },
  pcRequestId: {
    ...tableCellStyle(120),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  issuedOn: {
    ...tableCellStyle(120),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}));

export const InvoicesTableHeader: React.FC = () => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.transactionId}>
          {translate('transactionId')}
        </TableCell>
        <TableCell className={classes.serviceType}>
          {translate('serviceType')}
        </TableCell>
        <TableCell className={classes.invoiceNumber}>
          {translate('invoiceNumber')}
        </TableCell>
        <TableCell className={classes.expiredDate}>
          {translate('expiredDate')}
        </TableCell>
        <TableCell className={classes.invoiceAmount}>
          {translate('invoiceAmount')}
        </TableCell>
        <TableCell className={classes.requestsNumber}>
          {translate('status')}
        </TableCell>
        <TableCell className={classes.actions}>
          {translate('actions')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
