import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { CheckGreyIcon } from 'ui/icons/icons';

type SuccessMarkerProps = { wrapperHeight: number; markHeight?: number };

const useStyles = makeStyles<Theme, SuccessMarkerProps>((theme) => {
  const getMarkColor = theme.palette.secondary.main ;
  const getWrapperHeight = ({ wrapperHeight }: SuccessMarkerProps) => `${wrapperHeight}px`;
  const getMarkHeight = ({ wrapperHeight, markHeight }: SuccessMarkerProps) => `${markHeight || wrapperHeight}px`;

  return {
    markWrapper: {
      height: getWrapperHeight,
      width: getWrapperHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: (props) => `1px solid ${getMarkColor}`,
      borderRadius: '50%',
    },

    mark: {
      height: getMarkHeight,
      width: getMarkHeight,
      color: getMarkColor,
    },
  };
});

export const SuccessMarker: React.FC<SuccessMarkerProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.markWrapper}>
      <SvgIcon className={classes.mark} component={ CheckGreyIcon } />
    </div>
  );
};
